import * as React from 'react';
import { css } from '@emotion/core';
import { IconMail } from '../Icons';
import { footerTypo, paymentMethodIcons } from './Footer.helper';
import { Container } from '../Container';
import { Logo } from '../Logo';
import { Grid } from '../Grid';
import { colors, breakpoints } from '../../styles';
import { FooterNav } from '../FooterNav/FooterNav';
import { routesFooter } from '../../config/routes.config';
import { FooterPaymentMethods } from '../FooterPaymentMethods';
import { FooterHeadline } from '../FooterHeadline';

const footer = css({
  borderBlockStartWidth: 1,
  borderBlockStartStyle: 'solid',
  borderBlockStartColor: colors.theme,
  paddingBlockStart: 7 * 8,
  paddingBlockEnd: 5 * 8,
  backgroundColor: colors.gray2,
});

const footerInner = css({
  [`@media(min-width: ${breakpoints.LG}px)`]: {
    display: 'flex',
    justifyContent: 'space-between',
  }
});

const footerColumn = css({
  flex: '1 0',
});

const footerSideColumn = css({
  marginBlockEnd: 8 * 5,
});

const footerAddress = css([
  {...footerTypo},
  {
    fontStyle: 'normal',
    whiteSpace: 'nowrap',
    lineHeight: 1.5,
  }
]);

const footerAddressLine = css({
  marginBlockEnd: 8,
})

const footerLogo = css({
  marginBlockEnd: 4 * 8,
});

const mail = css({
  marginBlockEnd: 8 * 5,
  display: 'inline-flex',
  alignItems: 'center',
});

const mailLink = css({
  marginInlineStart: 8 * 2,
  fontSize: 14,
  fontWeight: 500,
  color: colors.theme,
});

const copy = css({
  fontSize: 14,
  color: colors.theme,
});

export const Footer: React.FC = () => (
  <div css={footer} id={'footer'}>
    <Container>
      <div css={footerInner}>
        <div css={footerColumn}>
          <div css={footerLogo}>
            <Logo />
          </div>
          <div css={mail}>
            <IconMail />
            <a css={mailLink} href='mailto:kontakt@seio.pl'>kontakt@seio.pl</a>
          </div>
        </div>
        <div css={footerColumn}>
          <Grid>
            <Grid.Column>
              <div css={footerSideColumn}>
                <FooterHeadline>
                  Płatności
                </FooterHeadline>
                <FooterPaymentMethods
                  list={paymentMethodIcons}
                />
              </div>
            </Grid.Column>
            <Grid.Column>
              <div css={footerSideColumn}>
                <FooterHeadline>
                  Oferta
                </FooterHeadline>
                <FooterNav
                  nav={routesFooter}
                />
              </div>
            </Grid.Column>
            <Grid.Column>
              <div css={footerSideColumn}>
                <FooterHeadline>
                  Właściciel serwisu
                </FooterHeadline>
                <address css={footerAddress}>
                  <p css={footerAddressLine}>Vsystems.pl</p>
                  <p css={footerAddressLine}>NIP: 6792908912</p>
                  <p css={footerAddressLine}>REGON: 366939245</p>
                </address>
              </div>
            </Grid.Column>
          </Grid>
        </div>
      </div>
      <div css={copy}>
        © Copyright 2020 by Seio
      </div>
    </Container>
  </div>
);
