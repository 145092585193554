import * as React from 'react';
import { css } from '@emotion/core';
import { Grid } from '../Grid';

interface Props {
  list: JSX.Element[][];
}

const root = css({
  display: 'flex',
});

const rootInner = css({
  flex: '1 0',
  maxWidth: 200 + 8 * 2,
  paddingInlineEnd: 8 * 2,
});

const element = css({
  ':not(:last-of-type)': {
    marginBlockEnd: 4 * 8,
  }
});

const iconWrapper = css({
  lineHeight: 0,
});

export const FooterPaymentMethods: React.FC<Props> = ({
  list,
}) => (
  <div css={root}>
    <div css={rootInner}>
      {list.map((row, i) => (
        <div css={element} key={i}>
          <Grid
            isThemeNoWrap={true}
            isThemeVerticallyAligned={true}
          >
            {row.map((icon, j) => (
              <Grid.Column key={j}>
                <div css={iconWrapper}>
                  {icon}
                </div>
              </Grid.Column>
            ))}
          </Grid>
        </div>
      ))}
    </div>
  </div>
);
