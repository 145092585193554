import * as React from 'react';
import { css } from '@emotion/core';

const column = css({
  position: 'relative',
  flexGrow: 1,
  flexBasis: 0,
  width: '100%',
  minHeight: 1,
  paddingInlineStart: 3 * 8 / 2,
  paddingInlineEnd: 3 * 8 / 2,
});

export const Column: React.FC = ({
  children
}) => (
  <div css={column}>
    {children}
  </div>
);
