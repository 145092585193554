import * as React from 'react';
import { css } from '@emotion/core';
import { footerTypo } from '../Footer/Footer.helper';

const footerHeadline = css([
  {...footerTypo},
  {
    marginBlockEnd: 3 * 8,
    fontWeight: 700,
    whiteSpace: 'nowrap',
  },
]);

export const FooterHeadline: React.FC = ({
  children,
}) => (
  <p css={footerHeadline}>
    {children}
  </p>
);
