import * as React from 'react';
import { css } from '@emotion/core';

import { IconRadarChart } from '../Icons';
import { colors, breakpoints } from '../../styles';
import { useWindowSize } from '../../utils/useWindowSize.hook';

export const BenefitsInfographic: React.FC = () => {
  const isDesktop = useWindowSize()?.innerWidth > breakpoints.MD;
  const isTablet = useWindowSize()?.innerWidth > breakpoints.SM;

  const chartStyles = {
    size: isDesktop ? 290 : isTablet ? 150 : 80,
    spacingVerticalStart: 8 * 5,
    spacingVerticalEnd: 8 * 11,
    spacingHorizontal: 8 * 18,
  };

  const chartCopyMiddleOffsetVertical = chartStyles.spacingVerticalStart + 8 * (isDesktop ? 12 : 3);
  const chartCopyMiddleOffsetHorizontal = chartStyles.spacingHorizontal + chartStyles.size + 8 * (isDesktop ? 3 : 2);
  const chartCopyBottomOffsetHorizontal = chartStyles.spacingHorizontal + chartStyles.size + 8 * (isDesktop ? -6 : -2);

  const root = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBlockEnd: 8 * 2,
  });

  const chartWrapper = css({
    position: 'relative',
    paddingBlockStart: chartStyles.spacingVerticalStart,
    paddingBlockEnd: chartStyles.spacingVerticalEnd,
    paddingInlineStart: chartStyles.spacingHorizontal,
    paddingInlineEnd: chartStyles.spacingHorizontal,
  });

  const chart = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 80,
    height: 80,
    [`@media(min-width: ${breakpoints.SM}px)`]: {
      width: 150,
      height: 150,
    },
    [`@media(min-width: ${breakpoints.MD}px)`]: {
      width: 290,
      height: 290,
    },
  });

  const chartCopy = css({
    position: 'absolute',
    marginBlockEnd: 0,
    fontSize: 12,
    fontWeight: 500,
    color: colors.gray1,
    lineHeight: 1.57,
    width: 120,
    [`@media(min-width: ${breakpoints.MD}px)`]: {
      width: 150,
      fontSize: 14,
    },
  });

  const chartCopyTop = css({
    top: chartStyles.spacingVerticalStart + (8 * -1),
    left: '50%',
    width: '100%',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    transform: 'translate(-50%, -100%)',
  });

  const chartCopyMiddleLeft = css({
    top: chartStyles.spacingVerticalStart + 8 * 3,
    right: chartStyles.spacingHorizontal + 80 + 8 * 2,
    textAlign: 'end',
    [`@media(min-width: ${breakpoints.SM}px)`]: {
      top: chartStyles.spacingVerticalStart + 8 * 6,
      right: chartStyles.spacingHorizontal + 150 + 8 * 3,
    },
    [`@media(min-width: ${breakpoints.MD}px)`]: {
      top: chartStyles.spacingVerticalStart + 8 * 12,
      right: chartStyles.spacingHorizontal + 290 + 8 * 3,
    }
  });

  const chartCopyMiddleRight = css({
    top: chartStyles.spacingVerticalStart + 8 * 3,
    left: chartStyles.spacingHorizontal + 80 + 8 * 2,
    [`@media(min-width: ${breakpoints.SM}px)`]: {
      top: chartStyles.spacingVerticalStart + 8 * 6,
      left: chartStyles.spacingHorizontal + 150 + 8 * 3,
    },
    [`@media(min-width: ${breakpoints.MD}px)`]: {
      top: chartStyles.spacingVerticalStart + 8 * 12,
      left: chartStyles.spacingHorizontal + 290 + 8 * 3,
    }
  });

  const chartCopyBottomLeft = css({
    bottom: chartStyles.spacingVerticalEnd,
    right: chartStyles.spacingHorizontal + 80 + 8 * -2,
    transform: 'translateY(100%)',
    textAlign: 'end',
    [`@media(min-width: ${breakpoints.SM}px)`]: {
      right: chartStyles.spacingHorizontal + 150 + 8 * -6,
    },
    [`@media(min-width: ${breakpoints.MD}px)`]: {
      right: chartStyles.spacingHorizontal + 290 + 8 * -6,
    },
  });

  const chartCopyBottomRight = css({
    bottom: chartStyles.spacingVerticalEnd,
    left: chartStyles.spacingHorizontal + 80 + 8 * -2,
    transform: 'translateY(100%)',
    [`@media(min-width: ${breakpoints.SM}px)`]: {
      left: chartStyles.spacingHorizontal + 150 + 8 * -6,
    },
    [`@media(min-width: ${breakpoints.MD}px)`]: {
      left: chartStyles.spacingHorizontal + 290 + 8 * -6,
    }
  });

  return (
    <div css={root}>
      <div css={chartWrapper}>
        <div css={chart}>
          <IconRadarChart />
          <p css={[chartCopy, chartCopyTop]}>
            Własna adresacja IP
          </p>
          <p css={[chartCopy, chartCopyMiddleRight]}>
            Przyjazna polityka
          </p>
          <p css={[chartCopy, chartCopyBottomRight]}>
            Profesjonalne Data Center w&nbsp;Polsce
          </p>
          <p css={[chartCopy, chartCopyBottomLeft]}>
            24/7 monitoring infrastruktury i&nbsp;reagowanie na awarie
          </p>
          <p css={[chartCopy, chartCopyMiddleLeft]}>
            Ochrona AntyDDoS
          </p>
        </div>
      </div>
    </div>
  );
};
