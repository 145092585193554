import * as React from 'react';
import { css } from '@emotion/core';
import { Column } from './Column';

interface Props {
  isThemeNoWrap?: boolean;
  isThemeVerticallyAligned?: boolean;
}

export class Grid extends React.Component<Props> {
  static Column = Column;

  render() {
    const {
      isThemeNoWrap,
      isThemeVerticallyAligned,
      children,
    } = this.props;

    const grid = css({
      display: 'flex',
      alignItems: isThemeVerticallyAligned ? 'center' : undefined,
      flexWrap: !isThemeNoWrap ? 'wrap' : undefined,
      marginInlineStart: -3 * 8 / 2,
      marginInlineEnd: -3 * 8 / 2,
    });

    return (
      <div css={grid}>
        {children}
      </div>
    );
  };
}
