import * as React from 'react';
import { BenefitsContainer } from '../BenefitsContainer';
import { Headline } from '../Headline';
import { BenefitsSteps } from '../BenefitsSteps';

export const SectionBenefitsWindows: React.FC = () => (
  <BenefitsContainer>
    <Headline>
      Dzięki Windows VPS zyskujesz
    </Headline>
    <BenefitsSteps
      list={[
        'Adres IP z tarczą bulletproof',
        'Dodatkowe adresy IP do serwera',
        'Automatyczne uruchomienie usługi',
        'Codzienny backup',
        'Dostęp przez VNC i RDP',
        'Pełna kontrola na koncie Administratora'
      ]}
    />
  </BenefitsContainer>
);
