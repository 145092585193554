import * as React from 'react';

export const IconDedicatedServer: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56">
    <g fill="#000634">
      <path d="M28 4a24 24 0 110 48 24 24 0 010-48zm0-4a28 28 0 100 56 28 28 0 000-56z"/>
      <path d="M40 27v-8H16v8h24zm-4-5.3a1.3 1.3 0 110 2.6 1.3 1.3 0 010-2.6zm-10.4 0h1.7L26 24.3h-1.7l1.2-2.6zm-2.9 0h1.7l-1.1 2.6h-1.7l1.1-2.6zm-2.9 0h1.7l-1.1 2.6h-1.7l1.1-2.6zM40 38v-8H16v8h24zm-4-5.3a1.3 1.3 0 110 2.6 1.3 1.3 0 010-2.6zm-10.4 0h1.7L26 35.3h-1.7l1.2-2.6zm-2.9 0h1.7l-1.1 2.6h-1.7l1.1-2.6zm-2.9 0h1.7l-1.1 2.6h-1.7l1.1-2.6z"/>
    </g>
  </svg>
);
