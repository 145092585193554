import * as React from 'react';
import { css } from '@emotion/core';
import { colors, breakpoints } from '../../styles';

interface Props {
  isThemeDark?: boolean;
  isThemeNoBorder?: boolean;
  isThemeBig?: boolean;
}

export const Headline: React.FC<Props> = ({
  isThemeDark,
  isThemeNoBorder,
  isThemeBig,
  children,
}) => {
  const color = isThemeDark ? colors.theme : colors.gray1;
  const fontSize = 8 * (isThemeBig ? 5 : 4);
  const borderWidth = isThemeNoBorder ? 0 : 2;

  const root = css({
    marginBlockEnd: 8 * (isThemeNoBorder ? 4 : 2),
  });

  const inner = css({
    display: 'inline-block',
    // borderBlockEndWidth: borderWidth,
    // borderBlockEndStyle: 'solid',
    // borderBlockEndColor: color,
    paddingBlockEnd: 8,
    fontSize: 8 * 3,
    fontWeight: 700,
    // fontFamily: 'IBM Plex Mono',
    color: color,
    lineHeight: 1.5,

    [`@media(min-width: ${breakpoints.MD}px)`]: {
      paddingBlockEnd: 0,
      fontSize: fontSize,
      lineHeight: 1.75,
    }
  });

  return (
    <h2 css={root}>
      <span css={inner}>
        {children}
      </span>
    </h2>
);
};
