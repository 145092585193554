import * as React from 'react';
import { Offer } from '../Offer';
import { OfferTiles } from '../OfferTiles';
import { IconDedicatedServer } from '../Icons';

export const SectionOfferDedicatedServers: React.FC = () => {
  const list = [
    {
      header: 'Serwer Pro 1',
      footer: '79 PLN / mc',
      link: 'https://seio.pl/panel/cart.php',
      content: [
        '1 vCPU Intel Xeon',
        '3 GB RAM',
        '30 SSD',
        'łącze 100 Mbit/s',
      ],
    },
    {
      header: 'Serwer Pro 2',
      footer: '79 PLN / mc',
      link: 'https://seio.pl/panel/cart.php',
      content: [
        '1 vCPU Intel Xeon',
        '5 GB RAM',
        '40 SSD',
        'łącze 150 Mbit/s',
      ],
    },
    {
      header: 'Serwer Pro 3',
      footer: '79 PLN / mc',
      link: 'https://seio.pl/panel/cart.php',
      content: [
        '2 vCPU Intel Xeon',
        '8 GB RAM',
        '50 SSD',
        'łącze 200 Mbit/s',
      ],
    },
    {
      header: 'Serwer Pro 4',
      footer: '79 PLN / mc',
      link: 'https://seio.pl/panel/cart.php',
      content: [
        '2 vCPU Intel Xeon',
        '8 GB RAM',
        '50 SSD',
        'łącze 200 Mbit/s',
      ],
    },    {
      header: 'Serwer Pro 5',
      footer: '79 PLN / mc',
      link: 'https://seio.pl/panel/cart.php',
      content: [
        '2 vCPU Intel Xeon',
        '8 GB RAM',
        '50 SSD',
        'łącze 200 Mbit/s',
      ],
    },
  ];

  return (
    <Offer
      title={'Serwery dedykowane'}
      icon={<IconDedicatedServer />}
      subtitle={'Dedykowane serwery markowych producentów. Najlepsze podzespoły i maksymalna wydajność. W pełni niezależne zasoby sprzętowe.'}
    >
      <OfferTiles
        list={list}
      />
    </Offer>
  );
};
