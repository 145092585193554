import * as React from 'react';
import { css } from '@emotion/core';
import { colors, breakpoints } from '../../styles';
import { IconArrowShort, IconArrow } from '../Icons';
import { useWindowSize } from '../../utils/useWindowSize.hook';

interface Props {
  header: string;
  content: string[];
  footer: string;
  link: string;
}

export const OfferTile: React.FC<Props> = ({
  header,
  content,
  footer,
  link,
}) => {
  const isDesktop = useWindowSize()?.innerWidth > breakpoints.MD;
  const gutterInline = 8 * 4;

  const root = css({
    minWidth: 280,
    maxWidth: isDesktop ? 320 : undefined,
    marginBlockEnd: 8 * (isDesktop ? 6 : 3),
  });

  const rootInner = css({
    paddingBlockStart: 8 * 4,
    paddingBlockEnd: 8 * 2,
    paddingInlineStart: gutterInline,
    paddingInlineEnd: gutterInline,
    backgroundColor: colors.gray1,
  });

  const cssHeader = css({
    marginBlockEnd: 8 * 4,
    fontSize: 28,
    fontWeight: 900,
    lineHeight: 1,
    color: colors.theme,
  });

  const cssContent = css({
    minHeight: 8 * 21,
  });

  const cssContentItem = css({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBlockEnd: 12,
    fontSize: 16,
    lineHeight: 1.2,
  });

  const cssContentIcon = css({
    display: 'inline-block',
    marginInlineEnd: 8 * 2,
    lineHeight: 0,
  });

  const cssFooter = css({
    marginBlockStart: 8 * 4,
    fontSize: 24,
    fontWeight: 900,
  });

  const cssLink = css({
    display: 'flex',
    alignItems: 'center',
    height: 8 * 6,
    paddingBlockStart: 8 * 2,
    paddingBlockEnd: 8 * 2,
    paddingInlineStart: gutterInline,
    paddingInlineEnd: gutterInline,
    fontSize: 16,
    fontWeight: 900,
    color: colors.theme,
    lineHeight: 1,
    textDecoration: 'none',
    backgroundColor: colors.gray3,
    transition: 'background-color 250ms ease 100ms',

    'span': {
      transition: 'transform 0.5s',
    },

    ':hover, :focus': {
      backgroundColor: colors.accent,
      
      'span': {
        transform: 'translate(8px)',
      }
    }
  });

  const linkArrow = css({
    display: 'inline-block',
    marginInlineStart: 8 * 3,
  });

  return (
    <div css={root}>
      <div css={rootInner}>
        <p css={cssHeader}>
          {header}
        </p>
        <div css={cssContent}>
          {content.map(item => (
            <p css={cssContentItem}>
              <span css={cssContentIcon}>
                <IconArrowShort />
              </span>
              {item}
            </p>
          ))}
        </div>
        <p css={cssFooter}>
          {footer}
        </p>
      </div>
      <a
        href={link}
        target={'_blank'}
        rel={'noopener noreferrer'}
        css={cssLink}
      >
        Zamów
        <span css={linkArrow}>
          <IconArrow />
        </span>
      </a>
    </div>
);
};
