import * as React from 'react';

export const IconRadarChart: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="288" height="275" viewBox="0 0 288 275">
    <g fill="none" fillRule="evenodd">
      <g stroke="#FFF" strokeDasharray="9" strokeLinecap="square" strokeWidth=".9">
        <path d="M273.6 98.1L136.8 144" transform="translate(7.2 7.2)"/>
        <path d="M136.8 98.1L0 144" transform="translate(7.2 7.2) matrix(-1 0 0 1 136.8 0)"/>
        <path d="M52.2 260.476L137.25 144" transform="translate(7.2 7.2)"/>
        <path d="M136.8 260.476L221.85 144" transform="translate(7.2 7.2) matrix(-1 0 0 1 358.65 0)"/>
        <path d="M137.25 0.45L137.25 144.45" transform="translate(7.2 7.2)"/>
      </g>
      <path stroke="#FFF" strokeWidth="1.8" d="M144 7.2L280.952 106.702 228.641 267.698 59.359 267.698 7.048 106.702z"/>
      <circle cx="144" cy="7.2" r="7.2" fill="#E0E812"/>
      <circle cx="59.2" cy="267.2" r="7.2" fill="#E0E812"/>
      <circle cx="228.2" cy="267.2" r="7.2" fill="#E0E812"/>
      <circle cx="280.8" cy="105.3" r="7.2" fill="#E0E812"/>
      <circle cx="7.2" cy="105.3" r="7.2" fill="#E0E812"/>
    </g>
  </svg>
);
