import { useEffect, useState } from 'react';

const getWindowSize = () => {
  const windowSize = typeof window !== `undefined` ? {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
  } : undefined;

  return windowSize;
};

export const useWindowSize = () => {
  const [size, setSize] = useState(getWindowSize());
  
  useEffect(() => {
    if (typeof window !== `undefined`) {
      const onResize = () => setSize(getWindowSize());
      window.addEventListener('resize', onResize);
      return () => window.removeEventListener('resize', onResize);
    }
  }, []);

  return size;
};
