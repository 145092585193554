import React from "react"
import { css } from '@emotion/core';

import "../base.css"
import "normalize.css"
import { colors } from "../../styles";

const layoutRoot = css({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
});

const layoutHeader = css({
  flex: '0 1',
});

const layoutContent = css({
  flex: '1 0',
  backgroundColor: colors.gray2,
});

const layoutFooter = css({
  flex: '0 1',
});

interface Props {
  header: React.ReactNode;
  footer: React.ReactNode;
}

export const Layout: React.FC<Props> = ({
  header,
  footer,
  children,
}) => {
  if (typeof window !== 'undefined') {
    // Make scroll behavior of internal links smooth
    require('smooth-scroll')('a[href*="#"]');
  }
  return (
    <div css={layoutRoot}>
      <div css={layoutHeader}>
        {header}
      </div>
      <div css={layoutContent}>
        {children}
      </div>
      <div css={layoutFooter}>
        {footer}
      </div>
    </div>
  );
}
