export const colors = {
  theme: '#000634',
  accent: '#e0e812',

  black: '#000000',
  white: '#ffffff',

  gray1: '#fcfcfd',
  gray2: '#eae8ec',
  gray3: '#d8d8d8',
  gray4: '#cccccc',
  gray5: '#171717',
};
