import * as React from 'react';
import { css } from '@emotion/core';

import { breakpoints } from '../../styles';
import { colors } from '../../styles/colors';
import { ArrowLink } from '../ArrowLink';

interface ProductProps {
  icon: React.ReactElement,
  name: string,
  desc: string,
  link: string,
}

export const ProductTile: React.FC<ProductProps> = ({
  icon,
  name,
  desc,
  link,
}) => {

  const root = css({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: colors.white,
    paddingBlockEnd: 60,
    paddingBlockStart: 60,
    paddingInlineStart: 36,
    paddingInlineEnd: 36,
    color: colors.theme,
    marginBottom: 20,
    textDecoration: 'none',

    ':hover': {
      borderBottom: 8,
      borderBottomColor: colors.accent,
      borderBottomStyle: 'solid',
      paddingBottom: 52,
    },

    [`@media(min-width: ${breakpoints.MD}px)`]: {
      width: '31%',
      marginBottom: 0,
    },
  });

  const productIcon = css({
    marginBottom: 32,

    [`@media(min-width: ${breakpoints.MD}px)`]: {
      marginBottom: 56,
    }
  });
  
  const productName = css({
    marginBottom: 30,
    fontSize: 24,
    fontWeight: 900,
  });
  
  const productDesc = css({
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.57,
    marginBottom: 36,
  });

  return (
    <div css={root}>
      <div>
        <div css={productIcon}>
          {icon}
        </div>
        <div css={productName}>
          {name}
        </div>
        <div css={productDesc}>
          {desc}
        </div>
      </div>
      <ArrowLink
        label={'Wybierz plan subskrypcji'}
        link={link}
      />
    </div>
  );
}
