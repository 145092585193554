import * as React from 'react';
import { BenefitsContainer } from '../BenefitsContainer';
import { Headline } from '../Headline';
import { Subheadline } from '../Subheadline';
import { Grid } from '../Grid';
import { BenefitsInfographic } from '../BenefitsInfographic';
import { useWindowSize } from '../../utils/useWindowSize.hook';
import { breakpoints } from '../../styles';
import { css } from '@emotion/core';

const renderText = () => {
  const subheadline = css({
    marginBlockStart: 8 * 4,
    marginBlockEnd: 8 * 4,
    width: '80%',
  });

  return (
  <>
    <Headline>
      Cechy naszych produktów
    </Headline>
    <div css={subheadline}>
      <Subheadline>
        Wszystkie serwery wyposażone są w nowoczesne dyski SSD i procesory Intel Xeon najnowszej generacji. Nasze usługi sprawdzą się idealnie w zastosowaniach SEO i aplikacjach takich jak GSA i Xrumer. Posiadamy własne adresacje IP.
      </Subheadline>
    </div>
  </>
);
};

const renderInfographic = () => {
  const infographic = css({
    paddingInlineStart: 0,

    [`@media(min-width: ${breakpoints.MD}px)`]: {
      paddingInlineStart: 8 * 3,
    }
  });

  return (
    <div css={infographic}>
      <BenefitsInfographic />
    </div>
  );
};

export const SectionFeatures: React.FC = () => {
  console.log('window sie -> ', useWindowSize());
  // const isDesktop = () => useWindowSize()?.innerWidth > breakpoints.MD;
  const isDesktop = () => typeof window !== `undefined` && window.innerWidth > breakpoints.MD;
  console.log('isDesktop -> ', isDesktop());

  const grid = css({
    display: 'flex',
    flexWrap: 'wrap',
    marginInlineStart: -3 * 8 / 2,
    marginInlineEnd: -3 * 8 / 2,
  });

  const column = css({
    position: 'relative',
    flexGrow: 1,
    flexBasis: 0,
    width: '100%',
    minHeight: 1,
    paddingInlineStart: 3 * 8 / 2,
    paddingInlineEnd: 3 * 8 / 2,
  });

  return (
    <BenefitsContainer> {/* jest oke */}
      {/* {isDesktop() ? ( */}
        <div css={grid}>
          <div css={column}>
            {renderText()}
          </div>
          <div css={column}>
            {renderInfographic()}
          </div>
        </div>
    </BenefitsContainer>
  );
};
