import { css } from '@emotion/core';
import * as React from 'react';
import { breakpoints } from '../../styles';

const root = css({
  paddingInlineStart: 8 * 3,
  paddingInlineEnd: 8 * 3,
  maxWidth: 1440,
  margin: 'auto',

  [`@media(min-width: ${breakpoints.MD}px)`]: {
    paddingInlineStart: 8 * 10,
    paddingInlineEnd: 8 * 10,
  }
});

export const Container: React.FC = ({ children }) => (
  <div css={root}>
    {children}
  </div>
);
