import * as React from 'react';
import { css } from '@emotion/core';
import { Headline } from '../Headline';
import { IconWindows, IconLinux, IconDedicatedServer } from '../Icons';
import { Container } from '../Container';
import { ProductTile } from '../ProductTile';
import { breakpoints, colors } from '../../styles';

const productsItems = [
  {
    icon: <IconWindows />,
    name: 'Serwery Windows',
    desc: 'Wydajne serwery VPS z Windows Server 2008 R2 lub Windows Server 2012. Serwery uruchomione są na nowoczesnych dyskach SSD będących gwarancją wydajności. Automatyczna aktywacja.',
    link: '/windows-vps'
  },
  {
    icon: <IconLinux />,
    name: 'Serwery Linux',
    desc: 'Serwery VPS z Linux, z profesjonalnymi dyskami SSD. Wysoka wydajność gwarantowana. Automatyczna aktywacja.',
    link: '/linux-vps'
  },
  {
    icon: <IconDedicatedServer />,
    name: 'Serwery dedykowane',
    desc: 'Dedykowane serwery markowych producentów. Najlepsze podzespoły i maksymalna wydajność. W pełni niezależne zasoby sprzętowe.',
    link: '/serwery-dedykowane'
  },
];

export const Products: React.FC = () => {
  const root = css({
    backgroundColor: colors.gray2,
    paddingBlockStart: 8 * 3,
    paddingBlockEnd: 8 * 3,

    [`@media(min-width: ${breakpoints.MD}px)`]: {
      paddingBlockStart: 8 * 10,
      paddingBlockEnd: 8 * 10,
    }
  });
  
  const products = css({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginBlockStart: 8 * 7,
  
    '@media(min-width: 1024px)': {
      flexDirection: 'row',
    }
  });
  
  return (
    <div css={root} id={'products'}>
      <Container>
        <Headline isThemeDark={true}>
          Nasze produkty
        </Headline>
        <div css={products}>
          {productsItems.map(item =>
            <ProductTile
              icon={item.icon}
              name={item.name}
              desc={item.desc}
              link={item.link}
              key={item.name}
            />
          )}
        </div>
      </Container>
    </div>
  );
};
