import * as React from 'react';

export const IconArrow: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="18" viewBox="0 0 34 18">
    <g fill="none" fillRule="evenodd">
        <path d="M0 0L17.777 0 17.777 17.777 0 17.777z" transform="matrix(-1 0 0 1 34 0)"/>
        <path fill="#000" fillRule="nonzero" d="M33.869 8.148L6.509 8.148 11.68 4.011 10.37 2.963 2.963 8.889 10.37 14.814 11.68 13.766 6.509 9.629 33.869 9.629z" transform="matrix(-1 0 0 1 34 0)"/>
    </g>
  </svg>
);
