import * as React from 'react';

export const IconVisa: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="43" height="13">
    <defs>
      <path id="a" d="M0 0h42.25v13H0z"/>
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a"/>
      </mask>
      <g fill="#000634" fillRule="nonzero" mask="url(#b)">
        <path d="M14.884 12.767L17.023.218h3.421l-2.14 12.549zM30.71.527a8.811 8.811 0 00-3.066-.528c-3.38 0-5.761 1.703-5.781 4.142-.02 1.803 1.7 2.81 2.997 3.41 1.33.615 1.779 1.008 1.772 1.557-.008.841-1.063 1.225-2.047 1.225a7.18 7.18 0 01-3.22-.658l-.44-.2-.48 2.81c1.22.45 2.51.676 3.81.669 3.596 0 5.93-1.683 5.957-4.29.013-1.43-.899-2.514-2.872-3.41-1.196-.58-1.928-.968-1.92-1.556 0-.52.62-1.08 1.96-1.08a6.314 6.314 0 012.56.481l.306.145.464-2.72M39.481.23h-2.643a1.698 1.698 0 00-1.79 1.04l-5.082 11.5h3.592s.585-1.546.72-1.885l4.38.005c.103.44.417 1.88.417 1.88h3.174L39.48.23m-4.217 8.088c.283-.723 1.365-3.51 1.365-3.51-.02.034.28-.726.455-1.198l.231 1.083.792 3.624-2.843.001zM12.015.227L8.666 8.785l-.357-1.739a9.792 9.792 0 00-4.737-5.265l3.062 10.975 3.619-.004L15.638.226h-3.622"/>
        <path d="M5.559.22H.043L0 .48c4.29 1.04 7.13 3.548 8.31 6.564l-1.2-5.767A1.431 1.431 0 005.56.217"/>
      </g>
    </g>
  </svg>
);
