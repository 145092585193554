import * as React from 'react';
import { Offer } from '../Offer';
import { OfferTiles } from '../OfferTiles';
import { IconLinux } from '../Icons';

export const SectionOfferLinux: React.FC = () => {
  const list = [
    {
      header: 'Linux SSD S',
      footer: '79 PLN / mc',
      link: 'https://seio.pl/panel/cart.php',
      content: [
        '1 vCPU Intel Xeon',
        '3 GB RAM',
        '30 SSD',
        'łącze 100 Mbit/s',
        'transfer bez limitu',
        '1 adres IP',
      ],
    },
    {
      header: 'Linux SSD M',
      footer: '79 PLN / mc',
      link: 'https://seio.pl/panel/cart.php',
      content: [
        '1 vCPU Intel Xeon',
        '5 GB RAM',
        '40 SSD',
        'łącze 150 Mbit/s',
        'transfer bez limitu',
        '1 adres IP',
      ],
    },
    {
      header: 'Linux SSD L',
      footer: '79 PLN / mc',
      link: 'https://seio.pl/panel/cart.php',
      content: [
        '2 vCPU Intel Xeon',
        '8 GB RAM',
        '50 SSD',
        'łącze 200 Mbit/s',
        'transfer bez limitu',
        '1 adres IP',
      ],
    },
    {
      header: 'Linux SSD XL',
      footer: '79 PLN / mc',
      link: 'https://seio.pl/panel/cart.php',
      content: [
        '2 vCPU Intel Xeon',
        '8 GB RAM',
        '50 SSD',
        'łącze 200 Mbit/s',
        'transfer bez limitu',
        '1 adres IP',
      ],
    },
  ];

  return (
    <Offer
      title={'Serwery Linux VPS'}
      subtitle={'Serwery VPS z Linux, z profesjonalnymi dyskami SSD. Wysoka wydajność gwarantowana. Automatyczna aktywacja.'}
      icon={<IconLinux />}
    >
      <OfferTiles
        list={list}
      />
    </Offer>
  );
};
