import * as React from 'react';
import { css } from '@emotion/core';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { breakpoints } from '../../styles';
import { Container } from '../Container';
import { colors } from '../../styles/colors';
import { ArrowLink } from '../ArrowLink';

export const Banner: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "banner.jpg" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  `);

  const root = css({
    backgroundColor: colors.theme,
    paddingBottom: 0,

    [`@media(min-width: ${breakpoints.MD}px)`]: {
      paddingBottom: 80,
    }
  });

  const bannerContainer = css({
    position: 'relative',
    height: 'unset',
    marginInlineStart: 8 * -3,
    marginInlineEnd: 8 * -3,
    
    [`@media(min-width: ${breakpoints.MD}px)`]: {
      height: 600,
      marginInlineStart: 0,
      marginInlineEnd: 8 * -10,
    }
  });
  
  const photo = css({
    display: 'none',
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: colors.gray3,
    
    [`@media(min-width: ${breakpoints.MD}px)`]: {
      display: 'block',
      width: 800,
    }
  });
  
  const textBox = css({
    position: 'relative',
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: 40,
    paddingInlineStart: 24,
    backgroundColor: colors.accent,
    height: 'unset',
    width: '100%',
    
    [`@media(min-width: ${breakpoints.XS}px)`]: {
      padding: 60,
      height: 400,
    },
    
    [`@media(min-width: ${breakpoints.MD}px)`]: {
      position: 'absolute',
      width: 660,
      height: 520,
      left: 0,
    }
  });
  
  const textBoxMain = css({
    color: colors.theme,
    fontSize: 28,
    fontWeight: 900,
    lineHeight: 1.35,
    marginBottom: 60,

    [`@media(min-width: ${breakpoints.XS}px)`]: {
      fontSize: 32,
    },

    [`@media(min-width: ${breakpoints.MD}px)`]: {
      fontSize: 45,
    }
  });
  
  return (
    <div css={root}>
      <Container>
        <div css={bannerContainer}>
          <div css={photo}>
            <Img fluid={data.file.childImageSharp.fluid} alt="Connections" />
          </div>
          <div css={textBox}>
            <h1 css={textBoxMain}>
              Serwery VPS<br />
              i dedykowane,<br />
              dla wymagających<br />
              aplikacji
            </h1>
            <ArrowLink
              label={'Sprawdź naszą ofertę'}
              link={'#products'}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};
