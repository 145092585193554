import { Link } from 'gatsby';
import * as React from 'react';
import { css } from '@emotion/core';
import { footerTypo } from '../Footer/Footer.helper';
import { Route } from '../../config/routes.config';

const footerNav = css({
  margin: 0,
  padding: 0,
  listStyle: 'none',
});

const footerNavListItem = css({
  display: 'block',
  lineHeight: '1em',
  marginBlockEnd: 8,
});

const footerNavItem = css([
  {...footerTypo},
  {
    textTransform: 'initial',
    textDecoration: 'initial',
    lineHeight: 1.5,
    whiteSpace: 'nowrap',

    ':hover, :focus': {
      textDecoration: 'underline',
    }
  }
]);

interface Props {
  nav: Route[];
}

export const FooterNav: React.FC<Props> = ({
  nav,
}) => (
  <ul css={footerNav}>
    {nav.map(navItem => (
      <li
        css={footerNavListItem}
        key={navItem.name}
      >
        <Link css={footerNavItem} to={navItem.path}>
          {navItem.name}
        </Link>
      </li>
    ))}
  </ul>
);
