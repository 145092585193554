import * as React from 'react';
import { css } from '@emotion/core';

import { IconArrow } from '../Icons';
import { colors } from '../../styles/colors';
import { Link } from 'gatsby';

interface Props {
  label: string;
  link: string;
}

export const ArrowLink: React.FC<Props> = ({
  label,
  link,
}) => {
  const textBoxLink = css({
    display: 'flex',
    textDecoration: 'none',
    color: colors.theme,
    fontSize: 16,
    fontWeight: 900,

    'svg': {
      transition: 'transform 0.5s',
    },

    ':hover': {
      'svg': {
        transform: 'translate(8px)',
      }
    },
  });

  const textBoxLinkText = css({
    marginRight: 24,
  });

  return (
    <Link css={textBoxLink} to={link}>
      <span css={textBoxLinkText}>{label}</span>
      <IconArrow />
    </Link>
  );
};
