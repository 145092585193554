import * as React from 'react';
import { css } from '@emotion/core';

import { breakpoints, colors } from '../../styles';

const circleSize = 8 * 5;

const root = css({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'stretch',

  [`@media(min-width: ${breakpoints.MD}px)`]: {
    flex: '1 0',
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
});

const shape = css({
  marginInlineEnd: 8 * 3,
  display: 'inline-flex',
  flexDirection: 'column',
  alignItems: 'center',

  [`@media(min-width: ${breakpoints.MD}px)`]: {
    marginInlineEnd: 0,
    flexDirection: 'row',
    width: '100%',
    transform: `translateX(calc(50% - ${circleSize / 2}px))`,
  }
});

const circle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: '0 0',
  flexBasis: circleSize,
  fontSize: 18,
  color: colors.white,
  width: circleSize,
  height: circleSize,
  borderRadius: '50%',
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: colors.accent,
});

const line = css({
  display: 'inline-block',
  width: 1,
  height: '100%',
  minHeight: 8 * 4,
  backgroundColor: colors.gray1,

  [`@media(min-width: ${breakpoints.MD}px)`]: {
    height: 1,
    minHeight: 'initial',
    width: '100%',
    minWidth: 8 * 3,
  }
});

const content = css({
  display: 'flex',
  alignItems: 'flex-start',
  marginBlockEnd: 8 * 2,
  fontSize: 14,
  fontWeight: 500,
  color: colors.gray1,
  lineHeight: 1.5,

  [`@media(min-width: ${breakpoints.MD}px)`]: {
    maxWidth: 180,
    marginBlockStart: 8 * 3,
    marginBlockEnd: 0,
    marginInlineStart: 8,
    marginInlineEnd: 8,
    textAlign: 'center',
  }
});

const contentInner = css({
  display: 'flex',
  alignItems: 'center',
  minHeight: circleSize,

  [`@media(min-width: ${breakpoints.MD}px)`]: {
    minHeight: 'initial',
  }
});

interface Props {
  hasLine?: boolean;
  number: number;
  text: string;
}

export const BenefitsStep: React.FC<Props> = ({
  hasLine,
  number,
  text,
}) => (
  <div css={root}>
    <span css={shape}>
      <span css={circle}>
        {number}
      </span>
      {hasLine && (
        <span css={line} />
      )}
    </span>
    <p css={content}>
      <span css={contentInner}>
        {text}
      </span>
    </p>
  </div>
);
