import * as React from 'react';
import { css } from '@emotion/core';

import { colors, breakpoints } from '../../styles';
import { Container } from '../Container';

const root = css({
  backgroundColor: colors.theme,
  paddingBlockStart: 8 * 3,
  paddingBlockEnd: 8 * 3,

  [`@media(min-width: ${breakpoints.MD}px)`]: {
    paddingBlockStart: 8 * 10,
    paddingBlockEnd: 8 * 10,
  }
});

export const BenefitsContainer: React.FC = ({ children }) => (
  <div css={root}>
    <Container>
      {children}
    </Container>
  </div>
);
