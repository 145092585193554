import * as React from 'react';
import { Offer } from '../Offer';
import { OfferTiles } from '../OfferTiles';
import { IconWindows } from '../Icons';

export const SectionOfferWindows: React.FC = () => {
  const list = [
    {
      header: 'Win SSD S',
      footer: '79 PLN / mc',
      link: 'https://seio.pl/panel/cart.php',
      content: [
        '1 vCPU Intel Xeon',
        '3 GB RAM',
        '30 SSD',
        'łącze 100 Mbit/s',
        'transfer bez limitu',
        '1 adres IP',
      ],
    },
    {
      header: 'Win SSD M',
      footer: '79 PLN / mc',
      link: 'https://seio.pl/panel/cart.php',
      content: [
        '1 vCPU Intel Xeon',
        '5 GB RAM',
        '40 SSD',
        'łącze 150 Mbit/s',
        'transfer bez limitu',
        '1 adres IP',
      ],
    },
    {
      header: 'Win SSD L',
      footer: '79 PLN / mc',
      link: 'https://seio.pl/panel/cart.php',
      content: [
        '2 vCPU Intel Xeon',
        '8 GB RAM',
        '50 SSD',
        'łącze 200 Mbit/s',
        'transfer bez limitu',
        '1 adres IP',
      ],
    },
    {
      header: 'Win SSD XL',
      footer: '79 PLN / mc',
      link: 'https://seio.pl/panel/cart.php',
      content: [
        '2 vCPU Intel Xeon',
        '8 GB RAM',
        '50 SSD',
        'łącze 200 Mbit/s',
        'transfer bez limitu',
        '1 adres IP',
      ],
    },
  ];

  return (
    <Offer
      title={'Serwery Windows VPS'}
      subtitle={'Wydajne serwery VPS z Windows Server 2008 R2 lub Windows Server 2012. Serwery uruchomione są na nowoczesnych dyskach SSD będących gwarancją wydajności. Automatyczna aktywacja.'}
      icon={<IconWindows />}
    >
      <OfferTiles
        list={list}
      />
    </Offer>
  );
};
