export interface Route {
  name: string;
  path: string;
}

const RouteConfig: Record<string, Route> = {
  WINDOWS_VPS: {
    name: 'Windows VPS',
    path: '/windows-vps',
  },
  LINUX_VPS: {
    name: 'Linux VPS',
    path: '/linux-vps',
  },
  DEDICATED_SERVERS: {
    name: 'Serwery dedykowane',
    path: '/serwery-dedykowane',
  },
}

export const routesFooter: Route[] = [
  RouteConfig.WINDOWS_VPS,
  RouteConfig.LINUX_VPS,
  RouteConfig.DEDICATED_SERVERS,
];

export const routesNav: Route[] = [
  RouteConfig.WINDOWS_VPS,
  RouteConfig.LINUX_VPS,
  RouteConfig.DEDICATED_SERVERS,
];
