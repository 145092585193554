import * as React from 'react';
import { BenefitsContainer } from '../BenefitsContainer';
import { Headline } from '../Headline';
import { BenefitsSteps } from '../BenefitsSteps';

export const SectionBenefitsLinux: React.FC = () => (
  <BenefitsContainer>
    <Headline>
      Dzięki Linux VPS zyskujesz
    </Headline>
    <BenefitsSteps
      list={[
        'Adres IP z tarczą bulletproof',
        'Dodatkowe adresy IP do serwera',
        'Automatyczne uruchomienie usługi',
        'Codzienny backup',
        'Dostęp przez VNC i SSH',
        'Wiele dystrybucji Linux do wyboru'
      ]}
    />
  </BenefitsContainer>
);
