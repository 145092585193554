import * as React from 'react';

export const IconMail: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16"
    height="13"
    viewBox="0 0 16 13"
  >
    <path fill="#000634" d="M13.824 0H1.536C.687 0 .008.68.008 1.52L0 10.64c0 .84.687 1.52 1.536 1.52h12.288c.849 0 1.536-.68 1.536-1.52V1.52c0-.84-.687-1.52-1.536-1.52zm0 10.64H1.536v-7.6l6.144 3.8 6.144-3.8v7.6zM7.68 5.32l-6.144-3.8h12.288L7.68 5.32z" />
  </svg>
);
