import * as React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import { Container } from '../Container';
import { Logo } from '../Logo';
import { colors } from '../../styles/colors';
import { breakpoints } from '../../styles/breakpoints';
import { BurgerMenu } from '../BurgerMenu';
import { routesNav } from '../../config/routes.config';

export const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  return (
    <>
      <div css={header}>
        <Container>
          <div css={headerContent}>
            <Link
              css={logoComponent}
              to={'/'}
              aria-label={'Logo'}
            >
              <Logo color={'light'} />
            </Link>
            {typeof window !== `undefined` && (window.innerWidth > 1024 ? (
              <div css={headerItem}>
                {routesNav.map(item => (
                  <Link
                    css={headerLink}
                    to={item.path}
                    key={item.name}
                    activeStyle={{
                      color: colors.white,
                      borderBottomWidth: 2,
                      borderBottomColor: colors.accent,
                      borderBottomStyle: 'solid',
                      marginBlockEnd: -2,
                    }}
                  >
                    {item.name}
                  </Link>
                ))}
                <a css={headerLink} href={'https://seio.pl/panel'}>Panel klienta</a>
                <a css={headerLink} href={'#footer'}>Kontakt</a>
              </div>
            ) : (
              <>
                <BurgerMenu
                  isOpen={isMenuOpen}
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                />
                <SideMenu isOpen={isMenuOpen}>
                  {routesNav.map(item => (
                    <Link
                      css={sideNavLink}
                      to={item.path}
                      key={item.name}
                    >
                      <div css={sideLink}>
                        {item.name}
                      </div>
                    </Link>
                  ))}
                  <a css={sideLink} href={'https://seio.pl/panel'}>Panel klienta</a>
                  <a
                    css={sideLink}
                    href={'#footer'}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Kontakt
                  </a>
                </SideMenu>
              </>
            ))}
          </div>
        </Container>
      </div>
      <div css={headerFoundation} />
    </>
  )
}

const headerHeight = 8 * 10;

const SideMenu = styled.div<{isOpen: boolean}>`
  position: fixed;
  top: 0;
  right: 0;
  ${({isOpen}) => isOpen ? 'transform: translate(0)' : 'transform: translate(100%)'};
  width: 100%;
  transition: transform 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${colors.theme};
  z-index: 2;
`;

const sideLink = css({
  padding: 20,
  color: colors.white,
  fontSize: 18,
  fontFamily: 'IBM Plex Mono',
  textDecoration: 'none',
});

const sideNavLink = css({
  textDecoration: 'none',
});

const headerItem = css({
  display: 'flex',
  flexDirection: 'row'
});

const headerContent = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: headerHeight,
  width: '100%'
});

const header = css({
  width: '100%',
  backgroundColor: colors.theme,
  position: 'fixed',
  zIndex: 2,

  [`@media(min-width: ${breakpoints.MD}px)`]: {
    position: 'static',
  }
});

const headerFoundation = css({
  height: headerHeight,

  [`@media(min-width: ${breakpoints.MD}px)`]: {
    display: 'none',
  }
});

const headerLink = css({
  paddingLeft: 5,
  paddingRight: 5,
  marginLeft: 48,
  color: colors.gray4,
  fontSize: 14,
  fontWeight: 500,
  textDecoration: 'none',

  ':hover': {
    borderBottomWidth: 2,
    borderBottomColor: colors.accent,
    borderBottomStyle: 'solid',
    marginBlockEnd: -2,
  }
});

const logoComponent = css({
  fontSize: 25,
  color: colors.white,
  zIndex: 3
});
