import * as React from 'react';
import { css } from '@emotion/core';
import { colors } from '../../styles';

interface Props {
  isThemeDark?: boolean;
}

export const Subheadline: React.FC<Props> = ({ isThemeDark, children }) => {
  const root = css({
    marginBlockEnd: 8 * 2,
    fontSize: 8 * 2,
    fontWeight: 900,
    color: isThemeDark ? colors.theme : colors.gray1,
    lineHeight: 1.75,
  });

  return (
    <p css={root}>
      {children}
    </p>
  );
};
