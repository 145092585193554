import * as React from 'react';

export const IconArrowShort: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0L18.14 0 18.14 18.14 0 18.14z" transform="matrix(-1 0 0 1 19 0)"/>
      <path fill="#000634" fill-rule="nonzero" d="M19 8.314L6.642 8.314 11.918 4.093 10.582 3.023 3.023 9.07 10.582 15.117 11.918 14.047 6.642 9.826 19 9.826z" transform="matrix(-1 0 0 1 19 0)"/>
    </g>
  </svg>
);
