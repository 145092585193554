type Breakpoint =
  | 'XXS'
  | 'XS'
  | 'SM'
  | 'MD'
  | 'LG'
  | 'XLG'
  | 'XXLG'
  | 'NAV'
  ;

export const breakpoints = {
  XXS: 360,
  XS: 576,
  SM: 768,
  MD: 1024,
  LG: 1200,
  XLG: 1550,
  NAV: 1200,
};

export const breakpointUp = (breakpoint: Breakpoint) =>
  breakpoints[breakpoint];

export const breakpointDown = (breakpoint: Breakpoint) =>
  breakpoints[breakpoint] - 1;
