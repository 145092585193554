import { css } from '@emotion/core';
import { IconBitcoin, IconMastercard, IconPaypal, IconVisa } from '../Icons';
import { colors } from '../../styles';

export const footerTypo = css({
  marginBlockEnd: 0,
  fontSize: 14,
  fontWeight: 500,
  color: colors.theme,
  textTransform: 'uppercase',
});

export const paymentMethodIcons = [
  [
    <IconVisa />,
    <IconMastercard />
  ],
  [
    <IconPaypal />,
    <IconBitcoin />
  ],
]
