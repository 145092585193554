import { css } from '@emotion/core';
import styled from '@emotion/styled';
import * as React from 'react';

import { colors } from '../../styles/colors';


export type BurgerMenuProps = {
  isOpen: boolean;
  onClick: () => void;
};

export const BurgerMenu: React.FC<BurgerMenuProps> = ({
  isOpen,
  onClick,
}) => (
  <button
    css={button}
    onClick={onClick}
    type={'button'}
    aria-label={'Menu'}
  >
    <BurgerIcon isOpen={isOpen}>
      <span />
    </BurgerIcon>
  </button>
);

const button = css({
  background: 'none',
  border: 'none',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 30,
  height: 30
});

const BurgerIcon = styled.span<{ isOpen: boolean }>`
  display: block;
  font-size: 0;
  z-index: 3;

  span,
  span::before,
  span::after {
    display: inline-block;
    width: 30px;
    height: 3px;
    border-radius: 2px;
    background: ${colors.accent};
    transition: 400ms;
  }

  span {
    position: relative;
  }

  span::before,
  span::after {
    content: '';
    position: absolute;
    left: 0;
  }

  span::before {
    top: 7px;
  }

  span::after {
    top: -7px;
  }

  ${({ isOpen }) => isOpen && `
    span::before,
    span::after {
      transform-origin: 50% 50%;
    }

    span {
      transition: background 300ms ease;
      background: transparent;
    }

    span::before,
    span::after {
      top: 0;
    }

    span::before {
      transform: rotate(45deg);
    }

    span::after {
      transform: rotate(-45deg);
    }
  `
  }
`;
