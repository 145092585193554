import * as React from 'react';
import { OfferTile } from '../OfferTile';
import { css } from '@emotion/core';
import { useWindowSize } from '../../utils/useWindowSize.hook';
import { breakpoints } from '../../styles';

interface ListItem {
  header: string;
  footer: string;
  link: string;
  content: string[];
}

interface Props {
  list: ListItem[];
}

export const OfferTiles: React.FC<Props> = ({ list }) => {
  const isTileFluid = useWindowSize()?.innerWidth <= breakpoints.XS;
  const isDesktop = useWindowSize()?.innerWidth > breakpoints.MD;
  const gutter = 8 * (isDesktop ? 3 : 1.5);

  const root = css({
    maxWidth: list.length > 4 ? 1050 : undefined,
    marginInlineStart: 'auto',
    marginInlineEnd: 'auto',
  });

  const row = css({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginInlineStart: gutter * -1,
    marginInlineEnd: gutter * -1,
  });

  const column = css({
    paddingInlineStart: gutter,
    paddingInlineEnd: gutter,
    flex: isTileFluid ? '1 0' : undefined,
  });

  return (
    <div css={root}>
      <div css={row}>
        {list.map(item => (
          <div css={column}>
            <OfferTile
              header={item.header}
              content={item.content}
              footer={item.footer}
              link={item.link}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
