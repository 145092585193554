import * as React from 'react';
import { css } from '@emotion/core';

import { breakpoints } from '../../styles';
import { BenefitsStep } from '../BenefitsStep/BenefitsStep';

const root = css({
  marginBlockStart: 8 * 5,

  [`@media(min-width: ${breakpoints.MD}px)`]: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginBlockStart: 8 * 12,
    overflow: 'hidden', // @NOTE: Because of <BenefitsStep /> transforms
  }
});

interface Props {
  list: string[];
}

export const BenefitsSteps: React.FC<Props> = ({ list }) => (
  <div css={root}>
    {list.map((listItem, i) => (
      <BenefitsStep
        number={i + 1}
        text={listItem}
        hasLine={i < list.length - 1}
        key={i}
      />
    ))}
  </div>
);
