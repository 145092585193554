import * as React from 'react';
import { css } from '@emotion/core';
import { colors, breakpoints } from '../../styles';
import { Container } from '../Container';
import { Headline } from '../Headline';
import { Subheadline } from '../Subheadline';
import { useWindowSize } from '../../utils/useWindowSize.hook';

interface Props {
  title: string;
  subtitle?: string;
  icon: React.ReactElement,
}

export const Offer: React.FC<Props> = ({
  title,
  subtitle,
  icon,
  children,
}) => {
  const isDesktop = useWindowSize()?.innerWidth > breakpoints.MD;

  const root = css({
    paddingBlockStart: 8 * (isDesktop ? 8 : 3),
    paddingBlockEnd: 8 * (isDesktop ? 4 : 3),
    backgroundColor: colors.gray2,
  });

  const subheadline = css({
    maxWidth: isDesktop ? '55%' : undefined,
    marginBlockEnd: 8 * (isDesktop ? 8 : 4),
  });

  const offerIcon = css({
    marginBottom: 8 * 2,
  })

  return (
    <div css={root}>
      <Container>
        <div css={offerIcon}>
          {icon}
        </div>
        <Headline
          isThemeBig={true}
          isThemeDark={true}
          isThemeNoBorder={true}
        >
          {title}
        </Headline>
        {subtitle && (
          <div css={subheadline}>
            <Subheadline isThemeDark={true}>
              {subtitle}
            </Subheadline>
          </div>
        )}
        {children}
      </Container>
    </div>
);
};
